:root {
  --color-white: #ffffff;

  --color-gray-lightest: #fcfcfc;
  --color-gray-lighter: #f2f2f2;
  --color-gray-light: #dfe1e2;

  --color-gray: #758482;

  --color-gray-dark: #373838;
  --color-gray-darker: #2f2f2f;

  --color-blue: #2185fb;
  --color-blue-50-percent: rgba(33, 133, 251, 0.5);
  --color-blue-10-percent: rgba(33, 133, 251, 0.1);

  --color-red: #ff4646;
  --color-red-10-percent: rgba(255, 70, 70, 0.1);

  --color-green: #1fc776;
  --color-green-10-percent: rgba(31, 199, 118, 0.1);

  --color-yellow-lightest: #fcf3e6;
  --color-yellow: #ffa41c;
  --color-yellow-10-percent: rgba(255, 164, 28, 0.1);

  --color-purple: #7856ff;

  --color-pink: #ff588a;

  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-normal: 400;

  --font-sans-serif: "Inter", Arial, Helvetica, sans-serif;
}
