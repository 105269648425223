/* .next {
  @apply bg-blue-button;

  background-color: var(--color-blue);
  border-color: var(--color-blue);

  transition: all 0.3s ease;
} */

.popover {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  & button {
    font-weight: 400;
  }
}

.dotContainer {
  display: inline-block;
}

.dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.15);
  margin-inline-end: 6px;

  &.active {
    background: #1677ff;
  }
}
