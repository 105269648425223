.link {
  color: var(--color-blue);
  text-decoration: none;
  cursor: pointer;
  padding: 0px;
  display: inline;
  height: auto;
  border: 0 !important;
}

.buttonBase {
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding-left: 16px;
  padding-right: 16px;
  transition: all 0.2s ease;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  font-weight: 600;
  outline: none;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-white);
    color: var(--color-blue);
    border-color: var(--color-blue);
  }
}

.small {
  height: 24px;
  font-size: 14px;
  line-height: 12px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
}

.warn {
  @apply text-red-500;
  @apply border-gray-300;
  @apply hover:!bg-gray-muted;
  @apply hover:!text-red-500;
}

.text {
  @apply text-blue-500;
  @apply border-none;
  @apply shadow-none;
  @apply outline-none;
}

.muted {
  @apply text-gray-suggest;
  @apply p-3;
  @apply hover:bg-gray-muted;
  @apply hover:!text-gray-suggest;
}

.secondary {
  @apply bg-blue-button;
  @apply text-white;
  @apply hover:bg-white;
  @apply hover:text-blue-500;

  &:disabled {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.blue {
  @apply bg-blue-button;

  &:hover {
    background-color: #4096ff;
  }
}

.accent {
  @apply bg-orange;
  @apply text-white;
  @apply hover:bg-white;
  @apply w-full;
}

.integration {
  @apply text-white;
  @apply w-full;
  @apply hover:!bg-white;
}

.text {
  color: var(--color-gray-darker);
}

.text-muted {
  padding: 0 !important;
  border: 0;

  &:hover {
    background: transparent;
  }
}

.buttonIconWithContent {
  margin-right: 8px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;

  .buttonBase {
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.square {
  width: 36px !important;
  min-width: 36px !important;
  padding: 0px;
}

.inline {
  display: inline-block;
  padding: 0px;
  line-height: inherit;
  font-size: inherit;
  height: auto;
}

.black {
  background-color: #000;
  color: white;
}

.disabled {
  background-color: var(--color-gray-light) !important;
  color: var(--color-gray) !important;
  border-color: var(--color-gray-light) !important;
  cursor: not-allowed;

  &:hover {
    background-color: var(--color-gray-light) !important;
    color: var(--color-gray) !important;
    border-color: var(--color-gray-light) !important;
  }
}
