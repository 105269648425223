@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import "react-datepicker/dist/react-datepicker.css";
@import "./vars.css";

@import "./datepicker-overrides.css";

html {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
  .hero {
    @apply w-full text-[#333];
  }
}

#__next {
  @apply h-full;
}

input:focus,
textarea:focus {
  outline-color: rgba(0, 0, 0, 0.8);
}

/**
 * The user invites modal uses a library (react-customize-token-input) that we need to overwrite
 * some custom styles on to match our theme
 */
#user-invites,
div.klever-token-input {
  height: 100px;
  @apply px-4;
  @apply py-4;
  border-color: #ababab;

  /* Make the tokens black with white text */
  .token-input-token {
    @apply bg-neutral;
    @apply text-primary-content;

    /* Make the X light against the dark background */
    .token-input-delete-button__close-icon:before,
    .token-input-delete-button__close-icon:after {
      border-left-color: #efefef;
    }
  }

  .token-input-token.token-input-token--active input {
    @apply text-primary-content;
  }

  .token-input-token.token-input-token--error {
    @apply bg-error;
    @apply text-error-content;
  }
}

#zendesk-tags {
  height: 50px;
  @apply py-2;
}

.button-disabled {
  color: #767d81;
  background-color: #d7d7d7;
  border: none;
}

.arrow-disabled {
  color: #767d81;
  border: none;
}

.link-disabled {
  pointer-events: none;
}

.modal-cancel-button {
  @apply text-center;
  @apply px-6;
  @apply py-3;
  @apply rounded-md;
  @apply border-2;
}

.modal-title {
  @apply py-4;
  @apply pl-6;
  @apply text-xl;
  @apply font-medium;
  @apply leading-6;
  @apply text-gray-900;
}

.modal-actions-container {
  @apply flex;
  @apply justify-end;
  @apply items-center;
  @apply gap-4;
  @apply py-2;
  @apply pr-6;
}

.modal-confirm-button {
  @apply px-6;
  @apply py-3;
  @apply rounded-md;
  @apply bg-blue-link;
  @apply text-white;
  @apply text-center;
}

.modal-dangerous-button {
  @apply px-6;
  @apply py-3;
  @apply rounded-md;
  background-color: #fb2121;
  @apply text-white;
  @apply text-center;
}

.hidden-on-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.hidden-on-desktop {
  @media (min-width: 768px) {
    display: none;
  }
}

.integration-connect-button {
  @apply w-full;
  @apply py-3;
  @apply rounded-md;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.modal-width {
  @media (max-width: 768px) {
    width: 270px;
  }
}

.border-main {
  @apply border-x;
  @apply border-y;
  border-color: var(--color-gray-light);
  @apply rounded-xl;
}

.chevron-color {
  color: var(--color-gray-light);
}

.ant-table-row .editable-col {
  @apply w-full;
}

.ant-table-row .editable-col .editable-cell-value-wrap {
  padding: 1px !important;
}

.ant-table-summary {
  z-index: unset !important;
}

.custom-dropdown {
  width: 148px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
}

.custom-ant-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-ant-modal .ant-modal-close,
.custom-ant-modal .ant-modal-close-x {
  inset-inline-end: 40px;
  color: var(--color-blue);
  font-size: 14px;
}

.custom-ant-modal .ant-modal-close:hover {
  background: transparent;
}

.custom-ant-modal .custom-ant-modal-scroll-body {
  max-height: 125px;
}

.custom-ant-modal .ant-modal-content {
  @apply w-full;
}

.ant-menu-inline {
  border-inline-end: none !important;
}

.ant-menu-title-content {
  display: flex;
}

.ant-tooltip {
  color: var(--color-gray-dark);
  border-radius: 10px;
  border-color: #e5e9ec;
}
.ant-tooltip-inner {
  color: var(--color-gray-dark);
}

.ant-tooltip .ant-tooltip-inner {
  color: var(--color-gray-dark);
  border-radius: 10px;
  border: solid #e5e9ec 1px;
}

.selected-border {
  border-inline-start: 15px solid #2185fb;
  left: -22px;
}

.unselected-border {
  border-inline-start: 15px solid #ffffff;
  left: -23px;
}

.unselected-border:hover {
  border-inline-start: 15px solid #f2f2f2;
  left: -22px;
}

.subheader {
  left: 14px;
}

.primary-cta-btn:hover {
  color: white !important;
  background-color: #1c7ff3;
}

.extras {
  opacity: 1;
  transition: opacity 1s ease;
}

.ant-input-search-button {
  border: 1px solid var(--color-gray-light);
}

/* Footer container for analysis tables */

.ant-custom-table-footer-container {
  position: absolute;
  transform: translateY(calc(100% - 4px));
}

@media (max-width: 460px) {
  .ant-custom-table-footer-container {
    width: 50%;
    transform: translateY(12px);
  }

  .ant-custom-table-footer-container .flex {
    flex-wrap: wrap;
  }

  .ant-custom-table-footer-container .ml-5 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .search-input {
    @apply min-w-full;

    .ant-select-selector {
      @apply w-full;
    }
  }
}

@media only screen and (min-width: 641px) {
  .search-input {
    width: 400px !important;
  }
}

.ant-popover .ant-popover-inner {
  padding: 4px;
}

.primary-button {
  border: 1px solid;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-radius: 0.6rem;
  text-align: center;
}

.create-form-modal .ant-modal-title {
  color: var(--color-gray-dark);
  font-size: 18px;
  font-weight: 600;
}

.center-heading-modal .ant-modal-header {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.center-heading-modal .ant-modal-title {
  color: var(--color-gray-dark);
}

.frame-button {
  height: 80px;
}

/* menu tweaks (less padding, etc.) */

.custom-popover-menu {
  border: 0 !important;
}

.custom-popover-menu .ant-menu-item {
  max-width: 200px;
  border: none;
}

.custom-popover-menu.sources-menu .ant-menu-item {
  min-width: 300px;
  max-width: 300px;
  display: flex;
  align-items: center;
}

.custom-popover-menu-scroller {
  overflow-y: auto;
  max-height: 260px;
  scrollbar-width: thin;
  scrollbar-color: #dfe1e2 #ffffff;
}

.custom-popover-menu-scroller::-webkit-scrollbar {
  width: 6px;
}

.custom-popover-menu-scroller::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 4px;
}

.custom-popover-menu-scroller::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 4px;
}

.custom-popover-menu .ant-menu-item .ant-menu-title-content div.flex {
  height: 100%;
}

.popover-menu-custom .ant-popover-inner {
  padding: 4px !important;
}

.popover-menu-custom .ant-menu-item {
  border: 0;
  border-inline-end: none !important;
}

.popover-menu-custom .ant-menu {
  border-inline-end: none !important;
}

.custom-sortable-table.transparent-hover-styles .ant-table-cell-row-hover {
  background: transparent !important;
}

.custom-sortable-table thead tr .ant-table-cell:before {
  content: "";
  display: none;
}

.custom-sortable-table thead tr .ant-table-cell {
  color: var(--color-gray);
  font-weight: 500;
  font-size: 14px;
}

.button-base {
  @apply font-semibold;
  @apply rounded-lg;
  @apply h-9;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.button-warn {
  @apply text-red-500;
  @apply border-gray-300;
  @apply hover:!bg-gray-muted;
  @apply hover:!text-red-500;
}

.button-text {
  @apply text-blue-500;
  @apply border-none;
  @apply shadow-none;
  @apply outline-none;
}

.button-muted {
  @apply text-gray-suggest;
  @apply p-3;
  @apply hover:bg-gray-muted;
  @apply hover:!text-gray-suggest;
}

.button-secondary {
  @apply bg-blue-button;
  @apply text-white;
  @apply hover:bg-white;
  @apply hover:text-blue-500;

  &:disabled {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.button-blue {
  @apply bg-blue-button;

  &:hover {
    background-color: #4096ff;
  }
}

.button-accent {
  @apply bg-orange;
  @apply text-white;
  @apply hover:bg-white;
  @apply w-full;
}

.button-integration {
  @apply text-white;
  @apply w-full;
  @apply hover:!bg-white;
}

.button-text-muted {
  padding: 0 !important;

  &:hover {
    background: transparent;
  }
}

.app-results-list {
  width: 300px;
  max-width: unset;
  border: 0 !important;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #dfe1e2 #ffffff;
}
.app-results-list::-webkit-scrollbar {
  width: 6px;
}

.app-results-list::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 4px;
}

.app-results-list::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 4px;
}

.app-results-list img {
  width: 30px !important;
  height: 30px !important;
}

.app-results-list .ant-menu-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-results-list .no-active:hover,
.app-results-list .no-active:active {
  background-color: transparent !important;
  cursor: unset;
}

.onboarding-card {
  .ant-card-body {
    @media (max-width: 640px) {
      padding: 0 !important;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/** Global tooltip styles **/
[role="tooltip"].popup-content {
  background-color: var(--color-gray-darker);
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--font-sans-serif);
}

[role="tooltip"].popup-content .popup-arrow {
  color: var(--color-gray-darker);
}

[role="tooltip"].white-content {
  background-color: white;
  color: var(--color-gray-dark);
  border: 1px solid var(--color-gray-light);
}

[role="tooltip"].white-content .white-arrow {
  color: white;
}

.best-value {
  border: 2px solid var(--color-purple);
  position: relative;
}

.best-value:before {
  content: "Best Value";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--color-purple);
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 8px;
}

@media print {
  .hide-print {
    display: none !important;
  }

  @page {
    margin: 0;
  }

  .page-break {
    page-break-before: always;
    display: initial;
  }
}

.page-break {
  display: none;
}
