.react-datepicker {
  background-color: var(--color-white);
  color: var(--color-gray-darker);
  position: relative;
  line-height: initial;
  display: flex;
  border-radius: 8px;
  border: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.react-datepicker-popper {
  z-index: 1000;
  line-height: 0;

  .react-datepicker__triangle {
    fill: var(--color-white) !important;
    color: var(--color-white) !important;
    stroke: unset;
  }
}

.react-datepicker__header {
  background-color: var(--color-white);
  border-bottom: none;
  padding: 0 0 4px 0;
}

.react-datepicker__week {
  padding: 6px 0;
}

.react-datepicker__day {
  margin: 0;
  width: 36px;
}

.react-datepicker__day:not(.react-datepicker__day--outside-month) {
  color: rgba(0, 0, 0, 0.88);
  padding: 1px;
}

.react-datepicker__week .react-datepicker__day {
  padding: 0px;
}

.react-datepicker__day--selected:not(.react-datepicker__day--outside-month) {
  border-radius: 5px;
  color: var(--color-white);
  background-color: var(--color-blue);
}

.react-datepicker__day--today {
  background: transparent;
  border: none;
}

.react-datepicker__month-text--today,
.react-datepicker__month-text--keyboard-selected {
  background: transparent;

  &:hover {
    background-color: var(--color-gray-lighter);
  }
}

.react-datepicker__day--disabled:not(.react-datepicker__day--outside-month) {
  color: var(--color-gray-light);
}

.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) {
  background-color: #e6f4ff;
  border-radius: 0;

  &:hover {
    color: white;
    background-color: var(--color-blue);
  }
}

.react-datepicker__day--range-start:not(.react-datepicker__day--outside-month) {
  color: white;
  background-color: var(--color-blue);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  div {
    background-color: unset;
  }
}

.react-datepicker__day--range-end:not(.react-datepicker__day--outside-month) {
  color: white;
  background-color: var(--color-blue);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  div {
    background-color: unset;
  }
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) {
  background-color: transparent;
  border-radius: 0;

  border-top: 1px dashed var(--color-blue);
  margin-top: -1px;

  border-bottom: 1px dashed var(--color-blue);
  margin-bottom: -1px;
}

.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--outside-month) {
  color: white;
  background-color: var(--color-blue);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px dashed var(--color-blue);

  div {
    border-color: var(--color-blue);
    background-color: unset;
  }
}

.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--outside-month) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px dashed var(--color-blue);
}

.react-datepicker__day--outside-month {
  pointer-events: none;
  color: var(--color-gray-light);
  border: none;
  box-shadow: none;
}
